<template>
<div class="card card-height-100">
    <img :src="absoluteUrl(item.image)" class="card-img-top" alt="">
    <div class="card-body">
        <h6 class="mb-2"> {{item.name}}</h6>
        <div class="mb-3"> 
            <span class=""> <strong>Price: </strong> ${{$filters.money_format(item.price)}}</span>
        </div>
        <div v-if="item.category" class="mb-1 small">
            <span> <strong>Category: </strong> {{item.category.name}}</span>
        </div>
        <div class="mb-1 small">
            <span> <strong>Position: </strong> {{item.sort_order}}</span>
        </div>
        <hr>
        <div class="">
            <div class="d-flex">
                <div class="form-check me-3">
                    <input disabled type="checkbox" :checked="item.is_active" class="form-check-input">
                    <label class="form-check-label" >Active</label>
                </div>
                <div class="form-check">
                    <input disabled type="checkbox" :checked="item.is_featured" class="form-check-input">
                    <label class="form-check-label" >Featured</label>
                </div>
            </div>
        </div>
        <hr>
        <slot name="actions"></slot>
    </div>
</div>

</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required: true
        }
    }, 
}
</script>

<style>

</style>